import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Heading,
    Text,
    Button,
    useDisclosure,
    Spinner,
    Flex,
} from '@chakra-ui/react';
import Emoji from 'components/ui/Emoji';
import styled from '@emotion/styled';

interface Props {
    currentMood: string;
    onSelect: (value: { label: string; character: string; term: string }) => void;
}

const StyledModalContent = styled(ModalContent)`
    background: linear-gradient(to right, #ec008c, #fc6767);
    border: none;
    border-radius: 12px;
    max-width: calc(100% - 30px);
    padding: 35px 0 0;
`;

function EmojiSelect({ currentMood = '', onSelect }: Props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [emojis, setEmojis] = useState([]);

    useEffect(() => {
        if (!isOpen || emojis.length > 0) {
            return;
        }

        axios
            .get(
                `https://emoji-api.com/categories/smileys-emotion?access_key=${process.env.REACT_APP_OPEN_EMOJI_API_KEY}
        `
            )
            .then((response) => {
                setEmojis(response.data);
            })
            .catch((error) => {
                throw new Error(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <>
            <Text color="white">Can&rsquo;t find the right mood?</Text>
            <Button mr="5px" w="50%" onClick={onOpen} variant="link" color="white">
                Choose your own
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <StyledModalContent>
                    <ModalCloseButton />
                    <ModalBody textAlign="center">
                        {emojis.length === 0 ? (
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="whiteAlpha.200"
                                color="white"
                                size="xl"
                            />
                        ) : (
                            <>
                                <Heading as="h3" size="lg" textAlign="center" color="white" mb="25px">
                                    Select the emoji that best sums up your mood.
                                </Heading>
                                <Flex wrap="wrap" justifyContent="center">
                                    {emojis.map((x: any) => {
                                        return (
                                            <Button
                                                key={x.slug}
                                                w="20%"
                                                fontSize="30px"
                                                variant="ghost"
                                                mb="8px"
                                                isActive={currentMood === x.unicodeName}
                                                onClick={() => {
                                                    onSelect({
                                                        label: x.slug,
                                                        character: x.character,
                                                        term: x.unicodeName,
                                                    });
                                                    onClose();
                                                }}
                                            >
                                                <Emoji label={x.slug}>{x.character}</Emoji>
                                            </Button>
                                        );
                                    })}
                                </Flex>
                            </>
                        )}
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="whiteAlpha" onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </StyledModalContent>
            </Modal>
        </>
    );
}

export default EmojiSelect;
