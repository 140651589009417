import React from 'react';
import styled from '@emotion/styled';

interface Props {
    children: React.ReactNode;
    label: string;
}

const Wrapper = styled.span`
    line-height: 1;
    display: block;
    position: relative;
    transform: translateY(1px);
`;

function Emoji({ children, label }: Props) {
    return (
        <Wrapper role="img" aria-label={label}>
            {children}
        </Wrapper>
    );
}

export default Emoji;
