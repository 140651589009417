import React from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

interface Props {
    children: React.ReactNode;
}

const Wrapper = styled(Flex)`
    height: 100vh;
`;

function PageWrapper({ children }: Props) {
    return <Wrapper direction="column">{children}</Wrapper>;
}

export default PageWrapper;
