import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, Heading, IconButton } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

function Header() {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <Flex alignItems="center" justifyContent="space-between" pt="25px" pb="25px">
            {location.pathname !== '/' && (
                <IconButton
                    colorScheme="blackAlpha"
                    mr="15px"
                    aria-label="Search gifs"
                    icon={<ArrowBackIcon />}
                    onClick={() => navigate('/')}
                />
            )}
            <Heading color="white" as="h1" size="xs">
                GIFS NOT WORDS
            </Heading>
        </Flex>
    );
}

export default Header;
