import React from 'react';
import { ChakraProvider, Container } from '@chakra-ui/react';
import theme from 'theme/theme';
import Router from 'router/Router';

function App() {
    return (
        <ChakraProvider theme={theme}>
            <Container maxW="4xl">
                <Router />
            </Container>
        </ChakraProvider>
    );
}

export default App;
