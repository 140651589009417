import React from 'react';
import styled from '@emotion/styled';
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, Portal, Box, Heading, Text } from '@chakra-ui/react';

interface Props {
    isOpen: boolean;
}

const StyledPopoverContent = styled(PopoverContent)`
    width: calc(100vw - 30px);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    padding: 20px 15px;
    position: relative;

    &:before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 10px 7px;
        border-color: transparent transparent rgba(0, 0, 0, 0.5) transparent;
        content: '';
        display: block;
        position: absolute;
        left: calc(50% - 7px);
        top: -10px;
    }
`;

function Instructions({ isOpen }: Props) {
    return (
        <Popover isOpen={isOpen} defaultIsOpen arrowSize={14}>
            <PopoverTrigger>
                <Box w="100%" height="8px" pointerEvents="none">
                    &nbsp;
                </Box>
            </PopoverTrigger>
            <Portal>
                <StyledPopoverContent>
                    <PopoverBody>
                        <Heading as="h3" size="lg" textAlign="center" color="white" mb="15px">
                            Let&rsquo;s get started!
                        </Heading>
                        <Text textAlign="center" color="white">
                            Use the slider to select an emoji that matches your current mood.
                        </Text>
                    </PopoverBody>
                </StyledPopoverContent>
            </Portal>
        </Popover>
    );
}

export default Instructions;
