import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Heading,
    Button,
    useDisclosure,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

const StyledModalContent = styled(ModalContent)`
    background: linear-gradient(to right, #ec008c, #fc6767);
    border: none;
    border-radius: 12px;
    max-width: calc(100% - 30px);
`;

function AboutModal() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Button mr="5px" w="50%" onClick={onOpen} colorScheme="whiteAlpha">
                What is this?
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <StyledModalContent>
                    <ModalHeader>
                        <Heading color="white" as="h3" size="xs">
                            GIFS NOT WORDS
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text color="white" pt="15px" pb="15px">
                            The purpose of this application is to present people who may be struggling mentally with a
                            method of communication, without the need to put feelings into words.
                            <br />
                            <br />
                            It&rsquo;s easy not to talk, but perhaps sharing a silly gif that represents your current
                            mood with a friend, colleague or loved one, might be all the conversation that is needed to
                            let that let that person know how you are feeling.
                        </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="whiteAlpha" onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </StyledModalContent>
            </Modal>
        </>
    );
}

export default AboutModal;
