import React, { useEffect, useRef, useState } from 'react';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { Carousel } from '@giphy/react-components';
import styled from '@emotion/styled';
import { Text, Flex, Box, Spinner } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

interface Props {
    searchTerm: string;
    sliderChanging: boolean;
    isHidden: boolean;
    onGifClick: (val: string | number) => void;
}

const StyledCarousel = styled(Carousel)`
    width: calc(100% + 15px);
`;

const StyledLoader = styled(Flex)`
    position: absolute;
    left: 50%;
    top: calc(50% - 26px);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

const giphyFetch = new GiphyFetch(`${process.env.REACT_APP_GIPHY_API_KEY}`);

function GifsCarousel({ searchTerm, sliderChanging, isHidden = true, onGifClick }: Props) {
    const noResultsRef = useRef(null);
    const fetchGifs = (offset: number) => giphyFetch.search(searchTerm, { offset, limit: 10 });
    const [gifCount, setGifCount] = useState(0);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        setFetching(true);
        fetchGifs(5).then((x) => {
            setGifCount(x.data.length);
            setFetching(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    return (
        <Box opacity={sliderChanging ? 0.6 : 1} position="relative" visibility={isHidden ? 'hidden' : 'visible'}>
            {fetching && (
                <StyledLoader alignItems="center">
                    <Spinner color="white" mr="10px" />
                    <Text color="white">Fetching gifs...</Text>
                </StyledLoader>
            )}
            <StyledCarousel
                onGifClick={(val: IGif, e: React.SyntheticEvent<HTMLElement, Event>) => {
                    e.preventDefault();
                    onGifClick(val.id);
                }}
                key={searchTerm}
                fetchGifs={fetchGifs}
                gifHeight={280}
                borderRadius={12}
                gutter={15}
                // eslint-disable-next-line react/jsx-boolean-value
                noLink={true}
                // eslint-disable-next-line react/jsx-boolean-value
                hideAttribution={true}
                noResultsMessage={
                    <Text ref={noResultsRef} textAlign="center" color="white" fontWeight="bold">
                        No gifs found for &ldquo;{searchTerm}&rdquo;.
                    </Text>
                }
            />
            {gifCount !== 0 && (
                <>
                    <Flex alignItems="center" justifyContent="flex-end">
                        <Text color="white" fontSize="xs">
                            Scroll
                        </Text>
                        <ArrowForwardIcon ml="3px" color="white" />
                    </Flex>
                    <Text color="white" fontSize="md" textAlign="center">
                        <strong>Click on a gif to share.</strong>
                    </Text>
                </>
            )}
        </Box>
    );
}

export default GifsCarousel;
