import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

interface Props {
    children: React.ReactNode;
}

const StyledFooter = styled(Box)`
    padding: 15px;
    width: 100%;
    z-index: 11;
    text-align: center;
    position: fixed;
    bottom: 0;
    right: 0;
    background: linear-gradient(to right, #ec008c, #fc6767);
    border-top: 1px solid white;
`;

function Footer({ children }: Props) {
    return <StyledFooter>{children}</StyledFooter>;
}

export default Footer;
