import React, { useState, useEffect } from 'react';
import GifSelect from 'components/content/GifSelect';
import PageWrapper from 'components/layout/PageWrapper';
import Header from 'components/layout/Header';
import PageBody from 'components/layout/PageBody';
import CheckIn from 'components/content/CheckIn';
import NotMobile from 'components/content/NotMobile';
import PageIntro from 'components/layout/PageIntro';

function Home() {
    const [isMobile, setIsMobile] = useState(true);

    const handleIsMobile = async () => {
        if (navigator.canShare === undefined) {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        handleIsMobile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageWrapper>
            <Header />
            {isMobile && <PageIntro />}
            <PageBody>{isMobile ? <GifSelect /> : <NotMobile />}</PageBody>
            {isMobile && <CheckIn />}
        </PageWrapper>
    );
}

export default Home;
