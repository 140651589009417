import React from 'react';
import { Heading } from '@chakra-ui/react';

function PageIntro() {
    return (
        <>
            <Heading textAlign="center" color="white" mb="15px" as="h2" size="3xl" pt="25px" lineHeight={1.1}>
                Hey, how
                <br />
                you doin&rsquo;?
            </Heading>
            {/* <Text textAlign="center" color="white" mb="35px" fontSize="1.05rem" maxW="550px" ml="auto" mr="auto">
                Sometimes it&rsquo;s easier to express your mood without words. Tap a gif to share yours.
            </Text> */}
        </>
    );
}

export default PageIntro;
