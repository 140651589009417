import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

interface Props {
    children: React.ReactNode;
}

const Body = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-bottom: 140px;
`;

function PageBody({ children }: Props) {
    return <Body flex="1">{children}</Body>;
}

export default PageBody;
