import React, { useState, useEffect } from 'react';
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb, Box } from '@chakra-ui/react';
import Emoji from 'components/ui/Emoji';
import GifsCarousel from 'components/content/GifsCarousel';
import GifsShare from './GifShare';
import Instructions from './Instructions';
import EmojiSelect from './EmojiSelect';

function GifSelect() {
    const [sliderIcon, setSliderIcon] = useState(<Emoji label="neutral-face">😐</Emoji>);
    const [sliderValue, setSliderValue] = useState(50);
    const [sliderChanging, setSliderChanging] = useState(false);
    const [searchTerm, setSearchTerm] = useState('not bad');
    const [selectedGifId, setSelectedGifId] = useState<string | number>();
    const [userInteracted, setUserInteracted] = useState(false);
    const [customMood, setCustomMood] = useState({ label: '', character: '', term: '' });

    useEffect(() => {
        if (sliderValue >= 0 && sliderValue < 15) setSliderIcon(<Emoji label="crying-face">😢</Emoji>);

        if (sliderValue >= 15 && sliderValue < 30) setSliderIcon(<Emoji label="slightly-frowning-face">🙁</Emoji>);

        if (sliderValue >= 30 && sliderValue < 40) setSliderIcon(<Emoji label="confused-face">😕</Emoji>);

        if (sliderValue >= 40 && sliderValue < 60) setSliderIcon(<Emoji label="neutral-face">😐</Emoji>);

        if (sliderValue >= 60 && sliderValue < 75) setSliderIcon(<Emoji label="slightly-smiling-face">🙂</Emoji>);

        if (sliderValue >= 75 && sliderValue < 90) setSliderIcon(<Emoji label="grinning-face">😀</Emoji>);

        if (sliderValue >= 90) setSliderIcon(<Emoji label="grinning-face-with-smiling-eyes">😄</Emoji>);
    }, [sliderValue]);

    useEffect(() => {
        if (customMood.term === '') {
            return;
        }

        setSearchTerm(customMood.term);

        if (customMood.character === '' && customMood.label === '') {
            return;
        }

        setSliderIcon(<Emoji label={customMood.label}>{customMood.character}</Emoji>);
    }, [customMood]);

    const handleMoodChange = (moodValue: number) => {
        if (moodValue >= 0 && moodValue < 15) setSearchTerm('depressed');

        if (moodValue >= 15 && moodValue < 30) setSearchTerm('sad');

        if (moodValue >= 30 && moodValue < 40) setSearchTerm('confused');

        if (moodValue >= 40 && moodValue < 60) setSearchTerm('not bad');

        if (moodValue >= 60 && moodValue < 75) setSearchTerm('happy');

        if (moodValue >= 75 && moodValue < 90) setSearchTerm('joyful');

        if (moodValue >= 90) setSearchTerm('excited');
    };

    return (
        <>
            <Box textAlign="center" mb="25px">
                <EmojiSelect currentMood={customMood.term} onSelect={(x) => setCustomMood(x)} />
            </Box>
            <Box mb="5px">
                <Box pl="15px" pr="15px">
                    <Slider
                        colorScheme="purple"
                        defaultValue={sliderValue}
                        size="lg"
                        onChange={(val) => {
                            setSliderValue(val);
                            setSliderChanging(true);
                        }}
                        onChangeEnd={(val) => {
                            handleMoodChange(val);
                            setSliderChanging(false);
                        }}
                        onChangeStart={() => {
                            setUserInteracted(true);
                            setSliderChanging(true);
                            setCustomMood({ label: '', character: '', term: '' });
                        }}
                    >
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb boxSize={12} fontSize="60px" bg="none">
                            {sliderIcon}
                        </SliderThumb>
                    </Slider>
                </Box>
                <Instructions isOpen={!userInteracted} />
            </Box>
            <Box>
                <GifsCarousel
                    sliderChanging={sliderChanging}
                    searchTerm={searchTerm}
                    onGifClick={(val) => setSelectedGifId(val)}
                    isHidden={!userInteracted}
                />
            </Box>
            {selectedGifId && (
                <GifsShare selectedGifId={selectedGifId as string} onSetGif={() => setSelectedGifId(undefined)} />
            )}
        </>
    );
}

export default GifSelect;
