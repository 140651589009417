import React, { useState, useEffect } from 'react';
import { Button, Spinner } from '@chakra-ui/react';
import styled from '@emotion/styled';

declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}

const StyledButton = styled(Button)`
    background-color: black;
    color: white;
    &:hover,
    &:focus {
        background-color: black;
        color: white;
    }
`;

interface Props {
    // eslint-disable-next-line react/require-default-props
    gifSrc?: string;
}

function ShareGif({ gifSrc }: Props) {
    const [file, setFile] = useState<File>();
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (!gifSrc) {
            return;
        }

        fetch(gifSrc)
            .then((res) => res.blob())
            .then((blob) => {
                setFile(new File([blob], 'mymoodgif.gif', { type: blob.type, lastModified: new Date().getTime() }));
                setDisabled(false);
            });
    }, [gifSrc]);

    const handleShare = async () => {
        if (!navigator.canShare) {
            return;
        }

        try {
            await navigator.share({
                files: file ? [file] : undefined,
                title: '',
            });
            window.dataLayer.push({ event: 'shared_gif' });
        } catch (error: any) {
            throw new Error(error);
        }
    };

    return (
        <StyledButton
            leftIcon={!gifSrc || disabled ? <Spinner /> : undefined}
            disabled={disabled}
            ml="5px"
            w="50%"
            onClick={handleShare}
        >
            {!gifSrc || disabled ? 'Preparing' : 'Share'}
        </StyledButton>
    );
}

export default ShareGif;
