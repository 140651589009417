import React from 'react';
import { Heading, Text } from '@chakra-ui/react';

function NotMobile() {
    return (
        <>
            <Heading textAlign="center" color="white" mb="15px" as="h2" size="3xl" pt="25px">
                Check-in on a friend.
            </Heading>
            <Text textAlign="center" color="white" mb="35px" fontSize="1.05rem" maxW="420px" ml="auto" mr="auto">
                To use this awesome app, you need a mobile device. Please come back and visit from your phone or tablet.
            </Text>
        </>
    );
}

export default NotMobile;
