import React, { useRef, useState, useEffect } from 'react';
import { useAsync } from 'react-async-hook';
import { IGif } from '@giphy/js-types';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Gif } from '@giphy/react-components';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Heading,
    Spinner,
    Box,
    Text,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import ShareGif from './ShareGif';

const giphyFetch = new GiphyFetch(`${process.env.REACT_APP_GIPHY_API_KEY}`);

interface Props {
    // eslint-disable-next-line react/require-default-props
    selectedGifId?: string;
    onSetGif: () => void;
}

const StyledDrawerContent = styled(DrawerContent)`
    padding: 25px 0 0;
    width: 100%;
    z-index: 21;
    text-align: center;
    position: fixed;
    bottom: 0;
    right: 0;
    background: linear-gradient(to right, #ec008c, #fc6767);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
`;

const StyledDrawerBody = styled(DrawerBody)`
    padding: 0 15px;
`;

const StyledDrawerFooter = styled(DrawerFooter)`
    padding: 15px;
    border-top: 1px solid white;
`;

function GifsShare({ selectedGifId, onSetGif }: Props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = useRef(null);
    const [gif, setGif] = useState<IGif | null>(null);

    useEffect(() => onOpen);

    useAsync(async () => {
        if (selectedGifId === undefined) {
            return;
        }

        const { data } = await giphyFetch.gif(selectedGifId);
        setGif(data);
    }, []);

    const handleClose = () => {
        onSetGif();
        onClose();
    };

    console.log('gif', gif);

    return (
        <Drawer isOpen={isOpen} placement="bottom" onClose={handleClose} finalFocusRef={btnRef}>
            <DrawerOverlay />
            <StyledDrawerContent>
                <DrawerCloseButton />
                <Heading as="h3" size="md" color="white" mb="5px" maxW="250px" ml="auto" mr="auto">
                    Does this gif show how you are feeling?
                </Heading>
                <Text color="white" mb="25px">
                    If so, just click share to send it!
                </Text>
                <StyledDrawerBody display="flex" justifyContent="center" mb="25px">
                    {gif ? (
                        <Gif gif={gif} width={250} borderRadius={12} noLink hideAttribution />
                    ) : (
                        <Box textAlign="center">
                            <Text color="white">Loading gif...</Text>
                            <Spinner color="white" mt="10px" />
                        </Box>
                    )}
                </StyledDrawerBody>
                <StyledDrawerFooter>
                    <Button w="50%" mr="5px" colorScheme="whiteAlpha" onClick={handleClose}>
                        Cancel
                    </Button>
                    <ShareGif gifSrc={gif?.images.original.url} />
                </StyledDrawerFooter>
            </StyledDrawerContent>
        </Drawer>
    );
}

export default GifsShare;
