import React from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import Footer from 'components/layout/Footer';
import styled from '@emotion/styled';
import AboutModal from './AboutModal';

declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}

const StyledButton = styled(Button)`
    background-color: black;
    color: white;
    &:hover,
    &:focus {
        background-color: black;
        color: white;
    }
`;

function CheckIn() {
    const handleShare = async () => {
        if (!navigator.canShare) {
            return;
        }

        try {
            await navigator.share({
                url: 'https://gifsnotwords.com',
                title: '',
                text: 'Just checking in. Fancy sharing your mood?',
            });
            window.dataLayer.push({ event: 'check_in' });
        } catch (error: any) {
            throw new Error(error);
        }
    };

    return (
        <Footer>
            <ButtonGroup p="0" w="100%">
                <AboutModal />
                <StyledButton ml="5px" w="50%" onClick={handleShare}>
                    Send to friend
                </StyledButton>
            </ButtonGroup>
        </Footer>
    );
}

export default CheckIn;
